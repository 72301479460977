<template>
    <div class="xrow">
        <div class="col-1-4">
            <div class="form-group">
                <label>Dia da Semana:</label>
                <label>
                    <select class="form-control" v-model="horario.diasemana">
                        <option value="1">Segunda-feira</option>
                        <option value="2">Terça-feira</option>
                        <option value="3">Quarta-feira</option>
                        <option value="4">Quinta-feira</option>
                        <option value="5">Sexta-feira</option>
                        <option value="6">Sábado</option>
                        <option value="0">Domingo</option>
                    </select>
                </label>
            </div>
        </div>
        <div class="col-1-4">
            <div class="form-group">
                <label>Horário de Inicio</label>                
                <input type="time" class="form-control" v-model="horario.horainicio"/>                
            </div>
        </div>
        <div class="col-1-4">
            <div class="form-group">
                <label>Horário de Fim</label>
                <input type="time" class="form-control" v-model="horario.horafim"/>                
            </div>
        </div>
        <div class="col-1-4">
            <div class="form-group">
                    <button class="button button-primary input-group-addon-right"
                            :disabled="checkCampos()"
                            title="Adicionar"
                            v-on:click="addExpedienteHorario"
                    >
                        +
                    </button>
            </div>
        </div>
        <div class="col-1-1">
            <div class="form-group">
                <h2>
                    Horário Configurados:
                </h2>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Dia da Semana</th>
                            <th>Horário de Início</th>
                            <th>Horário de Fim</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(i, index) in item">
                            <td>{{ index + 1 }}</td>
                            <td>{{ returnDiaSemana(i.diasemana) }}</td>
                            <td>{{ i.horainicio }}</td>
                            <td>{{ i.horafim }}</td>
                            <td>
                                <button class="button button-error"
                                        title="Remover"
                                        v-on:click="()=>removeItem(index)"
                                >
                                    <strong>&times;</strong>
                                </button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import _find from 'lodash/find';

export default {
  name: 'ExpedienteHorario',
  props: ['item'],
  watch: {    
  },
  computed: {    
  },
  data() {
    return {
    horario: {
        diasemana   : null,
        horainicio  : null,
        horafim     : null,
    },
    };
  },
  methods: {
    addExpedienteHorario() {        
        this.item.push({ ...this.horario });              
        this.resetForm();
    },
    removeItem(i) {
      this.item.splice(i, 1);
    },
    resetForm() {
      this.horario.diasemana  = '';
      this.horario.horainicio = '';
      this.horario.horafim    = '';
    },
    checkCampos() {
      return _find(this.item, {
        diasemana:  this.horario.diasemana,
        horainicio: this.horario.horainicio,
        horafim:    this.horario.horafim,
      });
    },
    returnDiaSemana(diaSemana) {
        diaSemana = '' + diaSemana;
        switch (diaSemana) {
            case '1':
                return 'Segunda-feira'                
            case '2':
                return 'Terça-feira'
            case '3':
                return 'Quarta-feira'
            case '4':
                return 'Quinta-feira'
            case '5':
                return 'Sexta-feira'
            case '6':
                return 'Sábado'
            case '0':
                return 'Domingo'
            default:
                return '*'
        }
    }
  },
};
</script>
