<template>
  <div>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-1">
        <div class="pull-right">
          <button v-on:click="openCreate" class="button button-success button-block">
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Descrição</th>
            <th>Dias da Semana</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td>
              {{ item.id }}
            </td>
            <td>{{ item.descricao }}</td>
            <td>
              <span v-for="weekDays in item.horarios" class="label primary" style="margin-right: 2px;">
                {{ returnDiaSemana(weekDays.diasemana) }}
              </span>
            </td>
            <td class="actions">
              <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>
              <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'expedienteList',
    props: [ 'items' , 'openEdit', 'openCreate', 'deleteConfirm'],
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {
    returnDiaSemana(diaSemana) {
        diaSemana = '' + diaSemana;
        switch (diaSemana) {
            case '1':
                return 'SEG'                
            case '2':
                return 'TER'
            case '3':
                return 'QUA'
            case '4':
                return 'QUI'
            case '5':
                return 'SEX'
            case '6':
                return 'SAB'
            case '0':
                return 'DOM'
            default:
                return '*'
        }
    }
    }
  }
</script>